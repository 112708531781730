import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {JobLocationHTTP} from '../../../../../providers/jobs';
import {PunchHTTP} from '../../../../../providers/punch';

import Modal from '../../../../../../../components/_modals/Modal';
import Loading from '../../../../../../../components/Loading';
import Select from '../../../../../../../components/_form/Select';
import Button from '../../../../../../../components/Button';

const UpdateJobLocationModal = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  punchId = '',
  selectedWork = '',
}) => {
  const {formatMessage} = useIntl();
  const {control, handleSubmit, reset} = useForm({
    defaultValues: {
      work_id: selectedWork,
    },
  });

  const [jobs, setJobs] = useState({
    loading: true,
    data: [],
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      await PunchHTTP.update(punchId, values);

      toast.success(formatMessage({id: 'toast.job-location-upd-success'}));
      onSuccess();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset({
      work_id: selectedWork,
    });
  }, [selectedWork]);

  useEffect(() => {
    async function fetchJobs() {
      setJobs({loading: true, data: []});
      const {data} = await JobLocationHTTP.get({status: 'active'});
      setJobs({
        loading: false,
        data: data.docs.map((j) => ({value: j._id, label: j.name})),
      });
    }

    if (open) fetchJobs();
  }, [open]);

  return (
    <Modal
      maxWidth="max-w-2xl"
      minHeight={220}
      open={open}
      onClose={onClose}
      title={formatMessage({id: 'assign-job-location'})}>
      {jobs.loading ? (
        <div className="m-4">
          <Loading />
        </div>
      ) : (
        <form
          className="flex flex-col justify-between h-full flex-1"
          onSubmit={handleSubmit(onSubmit)}>
          <div className="form-content">
            <Controller
              name="work_id"
              control={control}
              render={({field}) => (
                <Select
                  {...field}
                  label={formatMessage({id: 'job-location'})}
                  id="work_id"
                  options={jobs.data}
                />
              )}
            />
          </div>

          <div className="form-footer">
            <Button type="submit" loading={loading}>
              {formatMessage({id: 'save'})}
            </Button>
            <Button
              variant="secondary"
              disabled={loading}
              onClick={() => onClose()}
              extraClassName="mr-2">
              {formatMessage({id: 'cancel'})}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default UpdateJobLocationModal;
