import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {useAuth} from '../../../../../auth/hooks/useAuth';
import {useJobLocation} from '../../../../hooks/useJobLocation';
import {isValidDate, clearCurrencyValue} from '../../../../../../utils/utils';

import Input from '../../../../../../components/_form/Input';
import MaskedInput from '../../../../../../components/_form/MaskedInput';
import CurrencyInput from '../../../../../../components/_form/MaskedInput/Currency';
import Button from '../../../../../../components/Button';
import Modal from '../../../../../../components/_modals/Modal';

const Create = ({parentJob = {}, onClose = () => {}, open = false}) => {
  const {formatMessage} = useIntl();
  const {currentCompany, user} = useAuth();
  const {createJob} = useJobLocation();
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      if (!isValidDate(new Date(data.started_at))) {
        toast.error('Provider a valid start date!');
        return;
      }

      await createJob({
        ...data,
        parent_id: parentJob._id,
        location: parentJob.location,
        budget: clearCurrencyValue(data.budget),
        started_at: new Date(data.started_at).toDateString(),
        company_id: currentCompany || user.company_id,
        status: 'active',
      });

      toast.success('Job location created successfully!');
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      maxWidth="max-w-2xl"
      extraClasses="h-full"
      contentExtraClasses="h-full"
      minHeight={320}
      open={open}
      title={`Create child job location for ${parentJob.name}`}
      onClose={onClose}>
      <form
        className="flex flex-col flex-grow bg-white rounded-lg shadow overflow-hidden"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col flex-grow px-4 pt-5 sm:p-6 sm:pb-0">
          <div className="grid grid-cols-1 gap-4">
            <Input
              label={formatMessage({id: 'job-name-required'})}
              id="name"
              type="text"
              error={errors.name}
              {...register('name', {required: true})}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Controller
              rules={{
                required: true,
              }}
              name="budget"
              control={control}
              render={({field}) => (
                <CurrencyInput
                  {...field}
                  label={formatMessage({id: 'initial-budget-required'})}
                  error={errors.budget}
                />
              )}
            />
            <Controller
              rules={{
                required: true,
              }}
              name="started_at"
              control={control}
              render={({field}) => (
                <MaskedInput
                  {...field}
                  removeMask={false}
                  mask="99/99/9999"
                  maskPlaceholder="MM/DD/YYYY"
                  label={formatMessage({id: 'started-at-required'})}
                  error={errors.started_at}
                  alwaysShowMask={false}
                />
              )}
            />
          </div>
        </div>

        <div className="flex justify-end gap-4 bg-gray-50 px-4 py-3">
          <Button variant="secondary" onClick={onClose} disabled={loading}>
            {formatMessage({id: 'cancel'})}
          </Button>

          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default Create;
