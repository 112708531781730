import Dashboard from '../pages/Dashboard';

import Employees from '../pages/Users/Employees';
import EmployeeProfile from '../pages/Users/Employees/Profile';
import BossProfile from '../pages/Users/Boss/Profile/index';
import BossPage from '../pages/Users/Boss';

import Departments from '../pages/Departments/index';

import Timecard from '../pages/Timecard';
import TimecardView from '../pages/Timecard/View/index';

import JobLocation from '../pages/JobLocation';
import CreateJobLocation from '../pages/JobLocation/Create';
import EditJobLocation from '../pages/JobLocation/Edit';

import PunchesWoJobLocation from '../pages/Reports/PunchesWoJobLocation';
import PunchesMissedOut from '../pages/Reports/PunchesMissedOut';
import TimecardReports from '../pages/Reports/Timecard';
import EmployeeEarningsReports from '../pages/Reports/EmployeeEarnings';
// import JobLocationSummary from '../pages/Reports/JobLocationSummary';
import JobLocationReports from '../pages/Reports/JobLocation';

import Skills from '../pages/Skills';
import Whosin from '../pages/WhoIsIn';
import Tutorial from '../pages/Tutorial';

import ExtraBills from '../pages/Extras/ExtraBills';
import AddManHours from '../pages/Extras/ManHours';
import Receipts from '../pages/Extras/Receipts';
import ReceiptsCategory from '../pages/Extras/ReceiptsCategory';
import Photos from '../pages/Extras/Photos';

import Settings from '../pages/Settings';
import Plans from '../pages/Plans';

import Customers from '../pages/BookPodium/Customers';
import Estimates from '../pages/BookPodium/Estimates';
import EstimateEdit from '../pages/BookPodium/Estimates/pages/Edit';
import Invoices from '../pages/BookPodium/Invoices';
import InvoiceEdit from '../pages/BookPodium/Invoices/pages/Edit';

export default [
  {
    path: '/dashboard',
    element: Dashboard,
    exact: false,
    isPrivate: true,
  },
  // EMPLOYEES ROUTES
  {
    path: '/employees',
    element: Employees,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/employee/:id',
    element: EmployeeProfile,
    exact: false,
    isPrivate: true,
  },
  // BOSSES ROUTES
  {
    path: '/boss',
    element: BossPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/boss/:id',
    element: BossProfile,
    exact: false,
    isPrivate: true,
  },
  // SKILLS ROUTES
  {
    path: '/skills',
    element: Skills,
    exact: false,
    isPrivate: true,
  },
  // JOB LOCATION ROUTES
  {
    path: '/job-location',
    element: JobLocation,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/job-location/new',
    element: CreateJobLocation,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/job-location/:id',
    element: EditJobLocation,
    exact: false,
    isPrivate: true,
  },
  // DEPARTMENTS ROUTES
  {
    path: '/departments',
    element: Departments,
    exact: false,
    isPrivate: true,
  },
  // TIMECARD ROUTES
  {
    path: '/timecard',
    element: Timecard,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/timecard/:id',
    element: TimecardView,
    exact: false,
    isPrivate: true,
  },
  // SETTINGS
  {
    path: '/settings',
    element: Settings,
    exact: false,
    isPrivate: true,
  },
  // EXTRAS
  {
    path: '/extras/bills',
    element: ExtraBills,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/extras/guys',
    element: AddManHours,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/extras/employees-receipts',
    element: Receipts,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/extras/receipts-category',
    element: ReceiptsCategory,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/extras/photos',
    element: Photos,
    exact: true,
    isPrivate: true,
  },
  // WHO IS IN
  {
    path: '/who-is-in',
    element: Whosin,
    exact: true,
    isPrivate: true,
  },
  // REPORTS
  {
    path: '/reports/punches-whitout-job-location',
    element: PunchesWoJobLocation,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/reports/punches-missed-out',
    element: PunchesMissedOut,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/reports/timecard',
    element: TimecardReports,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/reports/employee-earnings',
    element: EmployeeEarningsReports,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/reports/job-location',
    element: JobLocationReports,
    exact: true,
    isPrivate: true,
  },
  // TUTORIAL
  {
    path: '/tutorial',
    element: Tutorial,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/plans',
    element: Plans,
    exact: true,
    isPrivate: true,
  },
  // CUSTOMERS
  {
    path: '/book-podium/customers',
    element: Customers,
    exact: false,
    isPrivate: true,
  },
  // ESTIMATES
  {
    path: '/book-podium/estimates',
    element: Estimates,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/book-podium/estimates/:id',
    element: EstimateEdit,
    exact: false,
    isPrivate: true,
  },
  // INVOICES
  {
    path: '/book-podium/invoices',
    element: Invoices,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/book-podium/invoices/:id',
    element: InvoiceEdit,
    exact: false,
    isPrivate: true,
  },
];
