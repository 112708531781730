/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {useFlags} from 'launchdarkly-react-client-sdk';

import {useQuery} from '../../../../hooks/useQuery';
import Loading from '../../../../components/Loading';

import StepsForm from './Steps';
import FullForm from './FullForm';

import logo from '../../../../assets/images/logos-inline-color.png';
import worker1 from '../../../../assets/images/worker1.jpeg';

const SignUpForm = ({flags}) => {
  if (flags && flags.registerForm) {
    return <StepsForm />;
  }

  return <FullForm />;
};

const SignUp = () => {
  const location = useLocation();
  const queryParams = useQuery(location.search);
  const comeFromLP = queryParams.get('lp');

  const flags = useFlags();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (flags && flags.registerForm) {
      setLoading(false);
    } else {
      setTimeout(() => {
        if (loading) setLoading(false);
      }, 5000);
    }
  }, [flags]);

  return (
    <div className="relative bg-white lg:h-screen lg:overflow-hidden">
      <div className="lg:h-full w-full lg:w-7/12">
        <div className="flex flex-col items-center justify-center w-full h-full p-4 pb-16 md:p-8">
          <img
            className="mx-auto h-10 w-auto mb-2 lg:h-12 lg:mb-4"
            src={logo}
            alt="WorkPodium - Logo"
          />
          {comeFromLP ? (
            <h1 className="text-2xl font-extralight text-center lg:text-4xl">
              Complete your{' '}
              <span className="font-bold text-yellow-500">registration</span>{' '}
              and <br />
              see the difference <span className="font-bold">
                WorkPodium
              </span>{' '}
              makes.
            </h1>
          ) : (
            <h1 className="text-xl font-extralight text-center lg:text-4xl">
              <FormattedMessage
                id="signup.title"
                values={{
                  span: (chunks) => <span className="font-bold">{chunks}</span>,
                }}
              />
              <br />
              <span className="hidden lg:block">
                <FormattedMessage
                  id="signup.title.two"
                  values={{
                    span: (chunks) => (
                      <span className="font-bold">{chunks}</span>
                    ),
                  }}
                />
              </span>
            </h1>
          )}
          {loading ? (
            <span className="mt-2 lg:mt-4">
              <Loading />
            </span>
          ) : (
            <SignUpForm flags={flags} />
          )}
        </div>
      </div>
      <div className="hidden lg:flex w-5/12 lg:absolute lg:inset-y-0 lg:right-0 bg-gradient-to-b from-yellow-500 to-yellow-700">
        <img
          className="h-full w-full object-cover lg:w-full lg:h-full opacity-10"
          src={worker1}
          alt="worker"
        />
      </div>
    </div>
  );
};

export default SignUp;
