import {useEffect, useState, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';

import {useJobLocation} from '../../../../hooks/useJobLocation';
import {currencyFormat} from '../../../../../../utils/currency';

import {FormatDate} from '../../../../../../components/FormatDate';
import Button from '../../../../../../components/Button';
import Search from '../../../../../../components/Search';
import Table from '../../../../../../components/Table';
import Badge from '../../../../../../components/Badge';

import CreateChildJob from '../Create';

const Home = ({parentJob}) => {
  const navigate = useNavigate();
  const {formatMessage} = useIntl();
  const {getJobs, jobs} = useJobLocation();

  const [state, setState] = useState({
    create: {
      open: false,
      loading: false,
    },
  });
  const [search, setSearch] = useState('');

  const handleFetchJobs = () => {
    getJobs({
      per_page: 150,
      page: 1,
      parent_id: parentJob._id,
    });
  };

  const dispatch = (value) => {
    setState((prev) => ({...prev, ...value}));
  };

  useEffect(() => {
    handleFetchJobs();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: 'name',
        Cell: ({row, value}) => {
          const hasAddress =
            row.original.location &&
            row.original.location.coordinates &&
            row.original.location.coordinates[0] &&
            row.original.location.coordinates[1] &&
            row.original.location.address;

          return (
            <>
              <p>{value}</p>
              {hasAddress ? (
                <p className="text-xs text-gray-500">
                  {row.original.location.address}
                </p>
              ) : (
                <Badge variant="error">
                  {formatMessage({id: 'joblocation.no-adress'})}
                </Badge>
              )}
            </>
          );
        },
      },
      {
        accessor: 'budget.$numberDecimal',
        Header: `${formatMessage({id: 'budget'})}`,
        Cell: ({value}) => currencyFormat(value),
      },
      {
        accessor: 'status',
        Header: `${formatMessage({id: 'status'})}`,
      },
      {
        accessor: 'updated_at',
        Header: `${formatMessage({id: 'updated-at'})}`,
        Cell: ({value}) => <FormatDate value={value} />,
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <Button onClick={() => navigate(`/job-location/${row.original._id}`)}>
            Edit
          </Button>
        ),
      },
    ],
    [formatMessage],
  );

  const filterJobs = () =>
    jobs.data.filter((j) =>
      j.name.toLowerCase().includes(search.toLowerCase()),
    );

  const jobsSearch = search.length > 0 ? filterJobs() : jobs.data;

  return (
    <div>
      <div className="flex items-center justify-between w-full p-6">
        <Search onChange={(e) => setSearch(e.target.value)} value={search} />
        <Button
          onClick={() => dispatch({create: {open: true, loading: false}})}>
          Create new children job
        </Button>
      </div>
      {jobs.loading ? (
        <p>loading...</p>
      ) : (
        <Table columns={columns} data={jobsSearch} />
      )}
      <CreateChildJob
        parentJob={parentJob}
        open={state.create.open}
        onClose={() => dispatch({create: {open: false, loading: false}})}
        onSuccess={() => handleFetchJobs()}
      />
    </div>
  );
};

export default Home;
