import {useEffect, useState} from 'react';
import {
  PlusIcon,
  ChevronRightIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import debounce from 'lodash/debounce';

import {useJobLocation} from '../../hooks/useJobLocation';
import {currencyFormat} from '../../../../utils/currency';

import PageHeader from '../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../components/SectionWrapper';
import {FormatDate} from '../../../../components/FormatDate';
import Button from '../../../../components/Button';
import Search from '../../../../components/Search';
import Badge from '../../../../components/Badge';
import Select from '../../../../components/_form/Select';
import Pagination from '../../../../components/Pagination';

const badgeStatusVariant = (status) => {
  if (status === 'done') return 'info';
  if (status === 'inactive') return 'secondary';

  return 'success';
};

const dateStatus = (job) => {
  if (job.status === 'done') return job.done_at;
  if (job.status === 'active') return job.started_at || job.created_at;

  return job.updated_at;
};

const JobLocationPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {getJobs, jobs, syncPunches} = useJobLocation();
  const {formatMessage} = useIntl();

  const [activeTab, setActiveTab] = useState('active');
  const [search, setSearch] = useState('');
  const [searchFiled, setSearchField] = useState('name');
  const [currentPage, setCurrentPage] = useState(1);

  const tabItems = [
    {
      value: 'all',
      label: formatMessage({id: 'all'}),
    },
    {
      value: 'active',
      label: formatMessage({id: 'active'}),
    },
    {
      value: 'done',
      label: formatMessage({id: 'done'}),
    },
    {
      value: 'inactive',
      label: formatMessage({id: 'inactive'}),
    },
  ];

  const handleFetchJobs = (searchTerm = '') => {
    getJobs({
      type: 'parent',
      status: activeTab,
      per_page: 28,
      page: currentPage,
      [searchFiled]: searchTerm || search,
    });
  };

  const handleSearch = (term = '') => {
    setSearch(term);
    handleFetchJobs(term);
  };

  useEffect(() => {
    handleFetchJobs();
  }, [activeTab, currentPage, searchFiled]);

  const searchDebounce = debounce(handleSearch, 500);

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'job-location'})}
        breadcrumbs={[
          {
            id: 'job-location',
            label: formatMessage({id: 'job-location'}),
          },
          {
            id: 'job-location-home',
            label: formatMessage({id: 'home'}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() => navigate('/job-location/new')}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {intl.formatMessage({id: 'new-jobLocation'})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={jobs.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={
          <>
            <div style={{minWidth: 120}}>
              <Select
                label=""
                id="search-field"
                className=""
                options={[
                  {
                    label: formatMessage({id: 'name'}),
                    value: 'name',
                  },
                  {
                    label: formatMessage({id: 'address'}),
                    value: 'address',
                  },
                  {
                    label: formatMessage({id: 'city'}),
                    value: 'city',
                  },
                ]}
                defaultValue="name"
                value={searchFiled}
                onChange={(e) => setSearchField(e)}
                placeholder="Select the field to search"
              />
            </div>
            <Search onChange={(e) => searchDebounce(e.target.value)} />
          </>
        }
      />

      <div className="grid grid-cols-2 gap-6 md:grid-cols-4 mb-6">
        {jobs.data.map((jb) => {
          const hasAddress =
            jb.location &&
            jb.location.coordinates &&
            jb.location.coordinates[0] &&
            jb.location.coordinates[1] &&
            jb.location.address;

          return (
            <div
              key={`job-location-item-${jb._id}`}
              className="flex flex-col items-start bg-white rounded-lg shadow overflow-hidden p-4">
              {jb.childrens && jb.childrens.length > 0 && (
                <Badge
                  extraClassName="flex items-center mb-2"
                  style={{flexShrink: 0}}
                  variant="info">
                  {jb.childrens.length} childrens
                </Badge>
              )}
              <div className="flex justify-between items-center w-full">
                <span className="text-xs text-gray-600">
                  <FormatDate value={dateStatus(jb)} />
                </span>
                <Badge variant={badgeStatusVariant(jb.status)}>
                  {jb.status}
                </Badge>
              </div>
              <h3>{jb.name}</h3>
              <span className="text-xs text-gray-600">
                {formatMessage({id: 'budget'})} :{' '}
                {currencyFormat(jb.budget.$numberDecimal)}
              </span>
              <div className="flex flex-grow-1 w-full h-full mt-2">
                {hasAddress ? (
                  <p className="text-xs text-gray-500">{jb.location.address}</p>
                ) : (
                  <Badge variant="secondary">
                    {formatMessage({id: 'joblocation.no-adress'})}
                  </Badge>
                )}
              </div>
              <div className="flex items-center justify-end gap-4 w-full mt-4">
                <Button variant="secondary" onClick={() => syncPunches(jb._id)}>
                  <>
                    <ArrowPathIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    {intl.formatMessage({id: 'joblocation-sync-punches'})}
                  </>
                </Button>
                <Button onClick={() => navigate(`/job-location/${jb._id}`)}>
                  <>
                    {intl.formatMessage({id: 'edit'})}
                    <ChevronRightIcon
                      className="ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      <Pagination
        totalPages={jobs.pagination.totalPages}
        currentPage={currentPage}
        totalRecords={jobs.pagination.totalDocs}
        onChangePage={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default JobLocationPage;
