/* eslint-disable no-param-reassign */
import {useCallback, useMemo, useReducer} from 'react';
import {createContext} from 'use-context-selector';
import {toast} from 'react-toastify';

import {JobLocationHTTP} from '../providers/jobs';

const initialState = {
  jobs: {
    loading: false,
    data: [],
    pagination: {
      hasNextPage: false,
      hasPrevPage: false,
      nextPage: 1,
      page: 1,
      prevPage: 1,
      totalDocs: 1,
      totalPages: 1,
    },
  },
  job: {
    loading: false,
    data: {},
  },
  sync: {
    loading: false,
  },
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case 'jobs.loading':
      return {
        ...state,
        jobs: {...initialState.jobs, loading: action.payload.loading},
      };
    case 'jobs':
      return {
        ...state,
        jobs: action.payload,
      };
    case 'job.loading':
      return {
        ...state,
        job: {...initialState.jobs, loading: action.payload.loading},
      };
    case 'job':
      return {
        ...state,
        job: action.payload,
      };
    case 'sync':
      return {
        ...state,
        sync: {loading: action.payload.loading},
      };
    default:
      return state;
  }
};

export const JobLocationContext = createContext({});

export function JobLocationProvider({children}) {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const getJobs = useCallback(async (params = {}) => {
    dispatch({type: 'jobs.loading', payload: {loading: true}});
    if (!params.per_page) params.per_page = 1000;
    const {data} = await JobLocationHTTP.get(params);
    dispatch({
      type: 'jobs',
      payload: {
        loading: false,
        data: data.docs,
        pagination: {
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage,
          nextPage: data.nextPage,
          page: data.page,
          prevPage: data.prevPage,
          totalDocs: data.totalDocs,
          totalPages: data.totalPages,
        },
      },
    });
  }, []);

  const getJob = useCallback(async (id) => {
    if (!id) return;
    dispatch({type: 'job.loading', payload: {loading: true}});
    const {data} = await JobLocationHTTP.getById(id);
    dispatch({type: 'job', payload: {loading: false, data}});
  }, []);

  const syncPunches = useCallback(async (id) => {
    if (!id) return;
    // dispatch({type: 'sync', payload: {loading: true}});
    const functionThatReturnPromise = () => JobLocationHTTP.syncPunches(id);
    toast.promise(functionThatReturnPromise, {
      pending: 'Sync punches',
      success: 'successfully synced punches! 👌',
      error: 'error when syncing 🤯',
    });
    // dispatch({type: 'sync', payload: {loading: false}});
  }, []);

  const createJob = useCallback(
    async (payload) => JobLocationHTTP.create(payload),
    [],
  );

  const updateJob = useCallback(
    async (id, payload) => JobLocationHTTP.update(id, payload),
    [],
  );

  const updateBudget = useCallback(
    async (id, payload) => JobLocationHTTP.updateBudget(id, payload),
    [],
  );

  const values = useMemo(
    () => ({
      jobs: state.jobs,
      job: state.job,
      sync: state.sync,
      getJobs,
      getJob,
      createJob,
      updateJob,
      updateBudget,
      syncPunches,
    }),
    [state, getJobs, getJob, createJob, updateJob, updateBudget, syncPunches],
  );

  return (
    <JobLocationContext.Provider value={values}>
      {children}
    </JobLocationContext.Provider>
  );
}
