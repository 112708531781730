import React from 'react';
import {createRoot} from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {withLDProvider} from 'launchdarkly-react-client-sdk';

import App from './App';

import './assets/css/app.css';

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: 'https://02abcf27392341149ceec1da51a05bed@o320857.ingest.sentry.io/6698502',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const LDProvider = withLDProvider({
  clientSideID: '67e91dd19ab4510996e9098e',
})(App);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<LDProvider />);
