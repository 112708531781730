/* eslint-disable no-unused-vars */
import {useState, useLayoutEffect, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate, Link} from 'react-router-dom';

import api from '../../../../services/api';
import {useQuery} from '../../../../hooks/useQuery';
import {useAuth} from '../../hooks/useAuth';

import Input from '../../../../components/_form/Input';
import Button from '../../../../components/Button';
import {LoadingBox} from '../../../../components/LoadingBox';
import {validateWhiteSpaces} from '../../../../utils/utils';

import logo from '../../../../assets/images/logos--wp-tmg-inline.png';
import worker1 from '../../../../assets/images/worker1.jpeg';

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQuery(location.search);

  const {signUp} = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState({
    loading: true,
    data: {},
  });

  const canUseEmail = async (email) => {
    try {
      if (email.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'full_email',
        value: email.toLowerCase(),
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        ...data,
        email: data.email.toLowerCase(),
        username: data.email.toLowerCase(),
        name: data.company_name,
        status: 'active',
        role: 'customer',
        plan_id: plan.data._id,
        plan_stripe_id: plan.data.stripe_id,
        trial: true,
        phone: '000 000-0000',
        language: 'pt',
      };
      await api.post('/subscription', payload);

      localStorage.setItem('@TrackMyGuys:ptBrLp', 'true');
      await signUp(payload);

      window.location.href = '/finalizar-cadastro';
    } catch (error) {
      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.errors
      ) {
        const errorsKeys = Object.keys(error.response.data.err.errors);
        errorsKeys.forEach((k) => {
          const errorObj = error.response.data.err.errors[k];
          const field = errorObj.path;
          const message = errorObj.message.replace('Path', 'Field');
          setError(field, {
            type: 'manual',
            message,
          });
        });
      }

      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.data &&
        error.response.data.err.data.param
      ) {
        let field = error.response.data.err.data.param;

        if (
          error.response.data.err.data.param === 'exp_year' ||
          error.response.data.err.dataparam === 'exp_month'
        ) {
          field = 'expiry';
        }
        if (error.response.data.err.data.param === 'number') {
          field = 'card_number';
        }

        setError(field, {
          type: 'manual',
          message: error.response.data.err.data.msg || 'invalid data',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    async function fetchPlan() {
      try {
        let planParam = queryParams.get('p');
        if (
          !planParam ||
          !['workpodium', 'workpodium-yearly'].includes(planParam)
        ) {
          planParam = 'workpodium';
        }
        const {data} = await api.get(`/plans/${planParam}`);
        setPlan({loading: false, data});
      } catch (error) {
        navigate('/teste-gratis?p=workpodium');
      }
    }

    fetchPlan();
  }, []);

  return (
    <>
      <div className="relative bg-white lg:h-screen lg:overflow-hidden">
        <div className="lg:h-full w-full lg:w-7/12">
          <div className="flex flex-col items-center justify-center w-full h-full p-4 md:p-8">
            <img
              className="mx-auto mb-4 mt-4 lg:mt-0"
              width={320}
              src={logo}
              alt="WorkPodium - Logo"
            />
            <h1 className="text-lg md:text-2xl font-extralight text-center lg:text-4xl">
              Crie sua conta <span className="font-bold">gratuita!</span>
              <br />E comece a usar{' '}
              <span className="font-bold">seu trial agora</span> mesmo.
            </h1>

            <form
              className="w-full max-w-xl px-4 md:px-10 mt-6"
              onSubmit={handleSubmit(onSubmit)}>
              <div className="w-full">
                <Input
                  label="Nome da sua empresa *"
                  id="company_name"
                  error={errors.company_name}
                  errorMessage={errors.company_name?.message}
                  autoFocus
                  {...register('company_name', {required: true})}
                />
              </div>

              <div className="mt-2 w-full">
                <Input
                  label="E-mail *"
                  id="email"
                  error={errors.email}
                  errorMessage={errors.email && errors.email.message}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Informe um email válido',
                    },
                    validate: {
                      checkUrl: async (value) =>
                        (await canUseEmail(value)) ||
                        'Este email já está em uso',
                    },
                  })}
                />
              </div>

              <div className="mt-4 w-full">
                <Input
                  label="Senha *"
                  id="password"
                  type="password"
                  error={errors.password}
                  errorMessage={errors.password && errors.password.message}
                  {...register('password', {
                    required: true,
                    minLength: {
                      value: 4,
                      message: 'precisa ter pelo menos 4 caracteres',
                    },
                    validate: {
                      whitespace: (val) =>
                        validateWhiteSpaces(val) ||
                        'Remova todos os espaços em branco',
                    },
                  })}
                />
              </div>

              <div className="mt-6">
                <Button
                  loading={loading}
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                  Criar conta
                </Button>
              </div>

              <div>
                <p className="mt-8 text-xs font-light text-center text-gray-400">
                  {' '}
                  Have an account?{' '}
                  <Link
                    to="/"
                    className="font-medium text-yellow-500 hover:text-yellow-600">
                    Login now
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
        <div className="hidden lg:flex w-5/12 lg:absolute lg:inset-y-0 lg:right-0 bg-gradient-to-b from-yellow-500 to-yellow-700">
          <img
            className="h-full w-full object-cover lg:w-full lg:h-full opacity-10"
            src={worker1}
            alt="worker"
          />
        </div>
      </div>

      {loading && <LoadingBox info="Creating your account..." />}
    </>
  );
};

export default SignUp;
