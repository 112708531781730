import {useAuth} from '../../../auth/hooks/useAuth';

import Button from '../../../../components/Button';

import logos from '../../../../assets/images/logos-inline-color.png';

const StartFlowCalendar = ({nextStep = () => {}}) => {
  const {user} = useAuth();

  const handleBooking = () => {
    if (window && window.clarity && user && user.name) {
      window.clarity('set', 'schedule-customer', user.name);
      window.clarity('set', 'schedule-customer-company', user.company.name);
    }
    const url = 'https://demo-trackmyguys.youcanbook.me/';
    window.open(url, '_blank').focus();
  };

  const handleWhatsapp = () => {
    if (window && window.clarity && user && user.name) {
      window.clarity('set', 'whatsapp-customer', user.name);
      window.clarity('set', 'whatsapp-customer-company', user.company.name);
    }
    const url =
      'whatsapp://send?phone=12038376850&text=Oi%2C%20preciso%20de%20ajuda%20para%20iniciar%20com%20o%20TrackMyGuys';
    window.open(url, '_blank').focus();
  };

  return (
    <div className="flex flex-col items-center justify-center p-8 max-w-2xl">
      <div className="px-8 py-4" style={{maxWidth: '80%'}}>
        <img src={logos} alt="Brands" />
      </div>

      <div className="flex flex-col items-center justify-center p-8 border border-gray-200 rounded">
        <p className="mt-2 text-md font-normal text-gray-700 text-center">
          Precisa de ajuda para dar seus primeiros passos e configurar tudo o
          que a sua empresa precisa em nosso sistema? Você pode agendar um
          horário para nossa equipe te apresentar o sistema, ou através do nosso
          suporte via ligação ou whatsapp: +1 (203) 837-6850
        </p>

        <div className="flex gap-4 mt-8 w-full" style={{maxWidth: 440}}>
          <Button onClick={() => handleBooking()} extraClassName="flex-grow">
            Agendar uma reunião
          </Button>
          <Button onClick={() => handleWhatsapp()} extraClassName="flex-grow">
            Chamar no WhatsApp
          </Button>
          <Button
            onClick={() => nextStep()}
            variant="secondary"
            extraClassName="flex-grow">
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StartFlowCalendar;
