import {useEffect, useState, useMemo, useCallback} from 'react';
import {PlusIcon, PencilSquareIcon, EyeIcon} from '@heroicons/react/24/outline';
import {toast} from 'react-toastify';
import debounce from 'lodash/debounce';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';

import {InvoicesHTTP} from '../../../providers/invoices';
import {useAuth} from '../../../../auth/hooks/useAuth';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import {FormatDate} from '../../../../../components/FormatDate';
import Button from '../../../../../components/Button';
import Search from '../../../../../components/Search';
import Table from '../../../../../components/Table';
import Pagination from '../../../../../components/Pagination';
import Modal from '../../../../../components/_modals/Modal';
import {LoadingBox} from '../../../../../components/LoadingBox';

import CreateForm from './components/CreateForm';

const configPage = {
  id: 'invoices',
  codePrefix: 'INV00',
  isEstimates: false,
};

const Estimates = () => {
  const navigate = useNavigate();
  const {formatMessage} = useIntl();
  const {currentCompany} = useAuth();

  const [formType, setFormType] = useState({
    loading: false,
    type: 'create',
    selected: {},
    modal: false,
  });
  const [state, setState] = useState({
    loading: true,
    data: [],
    meta: {},
    search: '',
    currentPage: 1,
  });

  const dispatchState = (newState) => {
    if (!newState) return;
    setState((prev) => ({...prev, ...newState}));
  };

  function handleEdit(itemId) {
    navigate(`/book-podium/invoices/${itemId}`);
  }

  async function fetchData() {
    dispatchState({
      loading: true,
      data: [],
      meta: {},
    });
    const {data} = await InvoicesHTTP.get({
      page: state.currentPage,
      isEstimate: configPage.isEstimates,
      ...(state.search ? {code: state.search} : {}),
    });
    dispatchState({
      loading: false,
      data: data.data,
      meta: data.meta,
    });
  }

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setFormType((prev) => ({...prev, loading: true}));
        const payload = values;

        await InvoicesHTTP.create({isEstimate: false, ...payload});
        toast.success('Invoice created successfully!');

        fetchData();
      } finally {
        setFormType({
          loading: false,
          type: 'create',
          selected: {},
          modal: false,
        });
      }
    },
    [formType, currentCompany],
  );

  useEffect(() => {
    fetchData();
  }, [state.search]);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'code'})}`,
        accessor: 'code',
        Cell: ({row}) => (
          <>
            <h4>{`${configPage.codePrefix}${row.original.code}`}</h4>
            {row.original.workName && <p>{row.original.workName}</p>}
          </>
        ),
      },
      {
        Header: `${formatMessage({id: 'updated-at'})}`,
        accessor: ({updatedAt}) => <FormatDate value={updatedAt} />,
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({row}) => (
          <div className="flex">
            <Button
              extraClassName="mr-2"
              onClick={() => handleEdit(row.original.id)}>
              <>
                <PencilSquareIcon
                  className="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                {formatMessage({id: 'edit'})}
              </>
            </Button>

            <Button
              variant="secondary"
              onClick={() =>
                window.open(
                  `https://app.workpodium.com/preview/pdf/${window.btoa(
                    row.original.id,
                  )}`,
                  '_blank',
                )
              }>
              <>
                <EyeIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {formatMessage({id: 'preview'})}
              </>
            </Button>
          </div>
        ),
      },
    ],
    [formatMessage],
  );

  const handleSetSearch = (term = '') => {
    dispatchState({search: term});
  };

  const searchDebounce = debounce(handleSetSearch, 500);

  return (
    <>
      <PageHeader
        title={formatMessage({id: `menu.bookpodium.${configPage.id}`})}
        breadcrumbs={[
          {
            id: 'bookpodium',
            label: formatMessage({id: 'menu.bookpodium'}),
          },
          {
            id: configPage.id,
            label: formatMessage({id: `menu.bookpodium.${configPage.id}`}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() => setFormType((prev) => ({...prev, modal: true}))}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {formatMessage({id: `bookpodium.${configPage.id}.new-button`})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={state.loading}
        toolbar={<Search onChange={(e) => searchDebounce(e.target.value)} />}>
        <Table columns={columns} data={state.data} />

        <Pagination
          totalPages={state.meta.lastPage}
          currentPage={state.currentPage}
          totalRecords={state.meta.total}
          onChangePage={(page) => dispatchState({currentPage: page})}
        />
      </SectionWrapper>

      <Modal
        maxWidth="max-w-2xl"
        open={formType.modal}
        onClose={() =>
          setFormType((prev) => ({...prev, modal: false, selected: {}}))
        }
        title={formatMessage({id: `bookpodium.${configPage.id}.new-button`})}>
        <CreateForm
          loading={formType.loading}
          onSubmit={(value) => handleSubmit(value)}
          onCancel={() =>
            setFormType((prev) => ({...prev, modal: false, selected: {}}))
          }
        />
      </Modal>

      {formType.loading && <LoadingBox info={`Saving ${configPage.id}...`} />}
    </>
  );
};

export default Estimates;
