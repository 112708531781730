import Tabs from '../Tabs';
import Loading from '../Loading';

const SectionWrapper = ({
  tabItems = [],
  onSelectTab = () => {},
  activeTab = '',
  toolbarTitle = '',
  toolbar,
  children,
  isLoading = false,
  showRoundedEnd = true,
  containerClassName = 'bg-white shadow-md',
}) => (
  <>
    <div
      className={`bg-gray-100 shadow-md md:rounded-t-lg ${
        !toolbar && 'border-b border-gray-200'
      } ${!children && 'mb-6'}`}>
      {tabItems && tabItems.length > 0 && (
        <Tabs
          items={tabItems}
          onChange={(val) => onSelectTab(val)}
          actived={activeTab}
        />
      )}

      {toolbarTitle && <h2 className="p-4">{toolbarTitle}</h2>}
      {toolbar && (
        <div
          className={`flex flex-col items-start gap-4 w-full ${
            children ? 'p-4' : 'p-4 md:rounded-b-lg'
          } bg-white md:flex-row`}>
          {toolbar}
        </div>
      )}
    </div>

    {children && (
      <div className={containerClassName}>
        {isLoading ? (
          <div className="w-full flex justify-center p-8">
            <Loading />
          </div>
        ) : (
          children
        )}
      </div>
    )}
    {showRoundedEnd && children && (
      <div className="w-full bg-gray-50 shadow-md p-1 md:rounded-b-lg" />
    )}
  </>
);

export default SectionWrapper;
