import 'react-toastify/dist/ReactToastify.css';
import 'react-grid-layout/css/styles.css';
// import 'react-resizable/css/styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// credit card plugin css
import 'react-credit-cards/es/styles-compiled.css';

// sempre adicionar arquivos .css antes desse import
import './styles/global.css';

import {BrowserRouter as Router} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {clarity} from 'react-microsoft-clarity';

import Routes from './routes';
import AppProviders from './providers';

clarity.init('guo951661i');

function App() {
  return (
    <AppProviders>
      <ToastContainer />
      <Router>
        <Routes />
      </Router>
    </AppProviders>
  );
}

export default App;
