/* eslint-disable radix */
import {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {Calendar} from 'react-date-range';
import {format as formatDate, addDays} from 'date-fns';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {JobLocationHTTP} from '../../../../providers/jobs';
import {PunchHTTP} from '../../../../providers/punch';

import Modal from '../../../../../../components/_modals/Modal';
// import Select from '../../../../../../components/_form/Select';
import TimePicker from '../../../../../../components/_form/TimePicker';
import Button from '../../../../../../components/Button';
import SearchSelect from '../../../../../../components/_form/SearchSelect';

const CreatePunchModal = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  period = {start: '', end: ''},
  userId = '',
}) => {
  const {
    control,
    watch,
    handleSubmit,
    reset,
    setError,
    formState: {errors},
  } = useForm();
  const {formatMessage} = useIntl();
  const periodStart = period.start.split('-');
  const periodEnd = period.end.split('-');

  const minDate = new Date(
    periodStart[2],
    parseInt(periodStart[0]) - 1,
    periodStart[1],
  );
  const maxDate = new Date(
    periodEnd[2],
    parseInt(periodEnd[0]) - 1,
    periodEnd[1],
  );
  const [date, setDate] = useState(minDate);
  const [jobs, setJobs] = useState({
    loading: true,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [hoursAlert, setHoursAlert] = useState(false);

  const fetchJobLocation = async () => {
    setJobs((prev) => ({...prev, loading: true}));
    const {data} = await JobLocationHTTP.get({
      status: 'active',
      per_page: 1000,
    });
    setJobs({
      loading: false,
      data: data.docs.map((job) => ({label: job.name, value: job._id})),
    });
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const handleCheckPeriodTime = (timeValue) => {
    // eslint-disable-next-line radix
    let hourValue = parseInt(timeValue.value.split(':')[0]);
    const minutesValue = timeValue.value.split(':')[1];

    let time = timeValue.value;
    if (timeValue.period === 'PM' && hourValue < 12) {
      hourValue += 12;
      time = `${hourValue}:${minutesValue}`;
    }

    return time;
  };

  const onSubmit = async (values) => {
    const startDate = formatDate(date, 'yyyy-MM-dd');
    const endDate = hoursAlert
      ? formatDate(addDays(date, 1), 'yyyy-MM-dd')
      : startDate;
    const startTime = handleCheckPeriodTime(values.started_at);
    const endTime = handleCheckPeriodTime(values.end_at);

    if (!values.work_id) return;
    if (!values.started_at || !values.end_at) return;
    if (!startDate || !endDate) return;
    if (!startTime) {
      setError('started_at', 'invalid');
      return;
    }
    if (!endTime) {
      setError('end_at', 'invalid');
      return;
    }
    if (startTime.includes('_')) {
      toast.error(formatMessage({id: 'toast.valid-start-time'}));
      setError('started_at', 'invalid');
      return;
    }
    if (endTime.includes('_')) {
      toast.error(formatMessage({id: 'toast.valid-finishing-time'}));
      setError('end_at', 'invalid');
      return;
    }

    const payload = {
      user_id: userId,
      work_id: values.work_id.value,
      start: {
        time: `${startDate}T${startTime}:00.000Z`,
      },
      end: {
        time: `${endDate}T${endTime}:00.000Z`,
      },
      created_device: 'web',
    };

    try {
      setLoading(true);

      await PunchHTTP.create(payload);
      toast.success(formatMessage({id: 'toast.punch-created'}));

      reset();
      setTimeout(() => onSuccess(), 300);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobLocation();
  }, []);

  const startTimeInput = watch('started_at');
  const endTimeInput = watch('end_at');

  useEffect(() => {
    if (startTimeInput && endTimeInput) {
      const startTimeValue = handleCheckPeriodTime(startTimeInput);
      const endTimeValue = handleCheckPeriodTime(endTimeInput);

      // eslint-disable-next-line radix
      const startHour = parseInt(startTimeValue.split(':')[0]);
      // eslint-disable-next-line radix
      const endHour = parseInt(endTimeValue.split(':')[0]);

      if (startHour > endHour) {
        setHoursAlert(true);
      } else {
        setHoursAlert(false);
      }
    }
  }, [startTimeInput, endTimeInput]);

  return (
    <Modal
      maxWidth="max-w-2xl"
      minHeight={320}
      open={open}
      onClose={onCancel}
      title={formatMessage({id: 'timecard.new-punch'})}>
      <form
        className="flex flex-col justify-between"
        style={{minHeight: 400}}
        onSubmit={handleSubmit(onSubmit)}>
        <div className="form-content">
          <div className="flex w-full pb-4 mb-4">
            {jobs.loading ? (
              formatMessage({id: 'timecard.loading-job-location'})
            ) : (
              <Controller
                rules={{
                  required: true,
                }}
                name="work_id"
                control={control}
                render={({field}) => (
                  <SearchSelect
                    {...field}
                    placeholder={formatMessage({
                      id: 'timecard.select-job-location',
                    })}
                    id="work_id"
                    options={jobs.data}
                    className="mt-2 w-full"
                    error={errors.work_id}
                  />
                )}
              />
            )}
          </div>
          <div className="flex">
            <div style={{width: 360}}>
              <p className="block text-sm font-medium text-gray-700">
                {formatMessage({id: 'timecard.select-day'})}
              </p>
              <div style={{marginTop: -10}}>
                <Calendar
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={(item) => setDate(item)}
                  date={date}
                  showMonthAndYearPickers={false}
                />
              </div>
              <p className="block text-xs text-gray-600">
                {formatMessage({id: 'timecard.only-create-new-punch'})}
              </p>
            </div>
            <div className="flex flex-col flex-grow ml-4">
              <Controller
                rules={{
                  required: true,
                }}
                name="started_at"
                control={control}
                render={({field}) => (
                  <TimePicker
                    {...field}
                    label={formatMessage({id: 'started-at-required'})}
                    error={errors.started_at}
                  />
                )}
              />
              <div className="mt-2">
                <Controller
                  rules={{
                    required: true,
                  }}
                  name="end_at"
                  control={control}
                  render={({field}) => (
                    <TimePicker
                      {...field}
                      label={formatMessage({id: 'finished-at-required'})}
                      error={errors.end_at}
                    />
                  )}
                />
              </div>

              {hoursAlert && (
                <p className="mt-4 font-normal text-xs text-yellow-600">
                  {formatMessage({id: 'timecard.alert-longer'})}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="form-footer">
          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => onCancel()}
            extraClassName="mr-2">
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreatePunchModal;
