import api, {apiTs} from '../../../services/api';

export const JobLocationHTTP = {
  get: (params = {}) => apiTs.get('/works', {params}),
  getById: (id = '') => api.get(`/jobs/${id}`),
  create: (payload = {}) => apiTs.post('/works', payload),
  update: (id = '', payload = {}) => api.put(`/jobs/${id}`, payload),
  updateBudget: (id = '', payload = {}) =>
    api.put(`/jobs/budget/${id}`, payload),
  syncPunches: (id = '') => api.get(`/jobs/sync-job/${id}`),
};
