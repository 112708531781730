/* eslint-disable no-unused-vars */
import {useState, useLayoutEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useLocation, useNavigate, Link} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useLDClient} from 'launchdarkly-react-client-sdk';

import api from '../../../../services/api';
import {useQuery} from '../../../../hooks/useQuery';
import {useAuth} from '../../hooks/useAuth';

import Input from '../../../../components/_form/Input';
import MaskedInput from '../../../../components/_form/MaskedInput';
import Button from '../../../../components/Button';
import {validateWhiteSpaces, validateUppercase} from '../../../../utils/utils';

const SignUp = () => {
  const ldClient = useLDClient();
  const navigate = useNavigate();
  const location = useLocation();
  const {formatMessage, locale} = useIntl();
  const queryParams = useQuery(location.search);
  const comeFromLP = queryParams.get('lp');

  const {signUp} = useAuth();
  const {
    control,
    register,
    handleSubmit,
    setError,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      name: queryParams.get('name'),
      email: queryParams.get('email'),
    },
  });

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [plan, setPlan] = useState({
    loading: true,
    data: {},
  });

  const nameValue = watch('name');
  const companyNameValue = watch('company_name');
  const phoneValue = watch('phone');

  const canUseEmail = async (email) => {
    try {
      if (email.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'full_email',
        value: email.toLowerCase(),
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  const canUseUsername = async (value) => {
    try {
      if (value.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'username',
        value,
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        ...data,
        username: data.username.replace(/\s+/g, ''),
        password: data.password.replace(/\s+/g, ''),
        email: data.email.toLowerCase().replace(/\s+/g, ''),
        status: 'active',
        role: 'customer',
        plan_id: plan.data._id,
        plan_stripe_id: plan.data.stripe_id,
        sale_id: queryParams.get('sid') ? queryParams.get('sid') : '',
        utm_source: queryParams.get('utm_source') || 'organic',
        utm_campaign: queryParams.get('utm_campaign') || 'organic',
        utm_id: queryParams.get('utm_id') || 'organic',
        utm_content: queryParams.get('utm_content') || 'organic',
        trial: true,
        language: locale || 'en',
      };
      await api.post('/subscription', payload);
      ldClient.track('account-create');

      await signUp(payload);
    } catch (error) {
      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.errors
      ) {
        const errorsKeys = Object.keys(error.response.data.err.errors);
        errorsKeys.forEach((k) => {
          const errorObj = error.response.data.err.errors[k];
          const field = errorObj.path;
          const message = errorObj.message.replace('Path', 'Field');
          setError(field, {
            type: 'manual',
            message,
          });
        });
      }

      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.data &&
        error.response.data.err.data.param
      ) {
        let field = error.response.data.err.data.param;

        if (
          error.response.data.err.data.param === 'exp_year' ||
          error.response.data.err.dataparam === 'exp_month'
        ) {
          field = 'expiry';
        }
        if (error.response.data.err.data.param === 'number') {
          field = 'card_number';
        }

        setError(field, {
          type: 'manual',
          message: error.response.data.err.data.msg || 'invalid data',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const firstSubmit = () => {
    if (!nameValue) setError('name');
    if (!companyNameValue) setError('company_name');
    if (!phoneValue) setError('phone');

    if (nameValue && companyNameValue && phoneValue) setStep(2);
  };

  useLayoutEffect(() => {
    async function fetchPlan() {
      try {
        let planParam = queryParams.get('p');
        if (
          !planParam ||
          !['workpodium', 'workpodium-yearly'].includes(planParam)
        ) {
          planParam = 'workpodium';
        }
        const {data} = await api.get(`/plans/${planParam}`);
        setPlan({loading: false, data});
      } catch (error) {
        navigate('/new-account?p=workpodium');
      }
    }

    fetchPlan();
  }, []);

  const loginInputs = () => (
    <>
      <div className="mt-4 grid gap-4 grid-cols-1">
        <Input
          label="Email *"
          id="email"
          error={errors.email}
          errorMessage={errors.email && errors.email.message}
          {...register('email', {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Provide a valid email',
            },
            validate: {
              checkUrl: async (value) =>
                (await canUseEmail(value)) || 'This email is already in use',
            },
          })}
        />
      </div>
      <div className="mt-4 grid gap-4 grid-cols-1 lg:grid-cols-2">
        <Input
          label={`${formatMessage({id: 'username'})}*`}
          id="username"
          error={errors.username}
          errorMessage={errors.username && errors.username.message}
          {...register('username', {
            required: true,
            minLength: {
              value: 4,
              message: 'needs to be at least 4 characters',
            },
            validate: {
              checkUrl: async (value) =>
                (await canUseUsername(value)) ||
                'This username is already in use',
              whitespace: (val) =>
                validateWhiteSpaces(val) || 'Remove all white spaces!',
              uppercaseChar: (val) =>
                validateUppercase(val) || 'Remove all uppercase letters!',
            },
          })}
        />

        <Input
          label={`${formatMessage({id: 'password'})}*`}
          id="password"
          type="password"
          error={errors.password}
          errorMessage={errors.password && errors.password.message}
          {...register('password', {
            required: true,
            minLength: {
              value: 4,
              message: 'needs to be at least 4 characters',
            },
            validate: {
              whitespace: (val) =>
                validateWhiteSpaces(val) || 'Remove all white spaces!',
            },
          })}
        />
      </div>
    </>
  );

  const initialInputs = () => (
    <div className="grid gap-4 mt-2 grid-cols-1 lg:grid-cols-2">
      <Input
        label={`${formatMessage({id: 'your_name'})}*`}
        id="name"
        error={errors.name}
        errorMessage={errors.name?.message}
        autoFocus
        {...register('name', {
          required: true,
          pattern: {
            value: /^[a-zA-Z\s]+$/i,
            message: 'Provide a valid name',
          },
        })}
      />

      <Input
        label={`${formatMessage({id: 'company_name'})}*`}
        id="company_name"
        error={errors.company_name}
        errorMessage={errors.company_name?.message}
        {...register('company_name', {required: true})}
      />

      <Controller
        rules={{
          required: true,
          minLength: 14,
          pattern: {
            value: /^[^_]*$/i,
            message: 'Provide a valid phone number',
          },
        }}
        name="phone"
        control={control}
        render={({field}) => (
          <MaskedInput
            {...field}
            label={`${formatMessage({id: 'phone'})}*`}
            mask="(999) 999-9999"
            maskPlaceholder="(999) 999-9999"
            alwaysShowMask={false}
            removeMask={false}
            error={errors.phone}
            errorMessage={errors.phone ? errors.phone.message : ''}
          />
        )}
      />
    </div>
  );

  return (
    <form
      className="w-full max-w-xl px-4 md:px-10 mt-6"
      onSubmit={handleSubmit(onSubmit)}>
      {step === 1 && initialInputs()}
      {step === 2 && loginInputs()}

      <div className="mt-6">
        {step === 1 && (
          <Button
            loading={loading}
            onClick={() => firstSubmit()}
            type="button"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
            {formatMessage({id: 'signup.cta.first'})}
          </Button>
        )}
        {step === 2 && (
          <Button
            loading={loading}
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
            {formatMessage({id: 'signup.cta'})}
          </Button>
        )}
      </div>

      <div>
        <p className="mt-8 text-xs font-light text-center text-gray-400">
          {' '}
          {formatMessage({id: 'signup.have-account'})}{' '}
          <Link
            to="/"
            className="font-medium text-yellow-500 hover:text-yellow-600">
            {formatMessage({id: 'signup.have-account.cta'})}
          </Link>
        </p>
      </div>
    </form>
  );
};

export default SignUp;
